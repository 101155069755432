import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { Props as BaseEntityLinkProps } from "scmp-app/components/base-entity-link";
import { HeaderAllMenuItemLink } from "scmp-app/components/header/header-oneline-menu-container/header-all-menu/common";
import type { headerAllMenuHighlightsQuery$key } from "scmp-app/queries/__generated__/headerAllMenuHighlightsQuery.graphql";

import { Container, LinkContainer, Title } from "./styles";

export type Props = {
  className?: string;
  queryParameter: BaseEntityLinkProps["query"];
  reference: headerAllMenuHighlightsQuery$key;
};

export const HeaderAllMenuHighlights: FunctionComponent<Props> = ({
  className,
  queryParameter,
  reference: reference_,
}) => {
  const queue = useFragment(
    graphql`
      fragment headerAllMenuHighlightsQuery on Query {
        highlightsQueue: queue(filter: { name: "all_menu_highlights" }) {
          items(first: 4) {
            edges {
              node {
                ... on Topic {
                  ...entityLink
                  name
                }
                ... on Section {
                  ...entityLink
                  name
                }
              }
            }
          }
        }
      }
    `,
    reference_,
  );

  return (
    <Container className={className}>
      <Title>Highlights</Title>
      <LinkContainer>
        {queue.highlightsQueue?.items?.edges.map((edge, index) => (
          <HeaderAllMenuItemLink key={index} query={queryParameter} reference={edge.node}>
            {edge.node.name}
          </HeaderAllMenuItemLink>
        ))}
      </LinkContainer>
    </Container>
  );
};

HeaderAllMenuHighlights.displayName = "HeaderAllMenuHighlights";
