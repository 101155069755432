import styled from "@emotion/styled";
import { fontRoboto } from "@product/scmp-sdk";
import { SwiperSlide } from "swiper/react";

import { BaseEntityLink } from "scmp-app/components/base-entity-link";
import { Swiper } from "scmp-app/components/swiper";

import IconArrowNextMobile from "./icon-arrow-next-mobile.svg?url";
import IconArrowNext from "./icon-arrow-next.svg?url";
import IconArrowPreviousMobile from "./icon-arrow-previous-mobile.svg?url";
import IconArrowPrevious from "./icon-arrow-previous.svg?url";

export const StyledBaseEntityLink = styled(BaseEntityLink)``;

export const MenuItem = styled.span`
  color: #001246;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 120%;

  transition: color 0.2s ease-in-out;

  &:hover {
    color: #2957b5;
  }
`;

export const Container = styled.div`
  min-inline-size: 0;
`;

export const StyledSwiper = styled(Swiper)`
  position: relative;

  margin: 0;
  padding: 0;

  overflow-y: visible;

  .swiper-wrapper {
    block-size: 24px;
  }

  .swiper-button-prev {
    inset-inline-start: 0;

    margin-inline-start: -9px;

    background-image: url(${IconArrowPreviousMobile});

    ${props => props.theme.breakpoints.up("desktop")} {
      background-image: url(${IconArrowPrevious});
    }
  }

  .swiper-button-next {
    inset-inline-end: 0;

    margin-inline-end: -9px;

    background-image: url(${IconArrowNextMobile});

    ${props => props.theme.breakpoints.up("desktop")} {
      background-image: url(${IconArrowNext});
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    inset-block-start: 0;

    inline-size: 24px;
    block-size: 24px;
    margin: 0;

    background-size: 24px 24px;

    box-shadow: none;
  }
`;

export const StyledSwiperSlide = styled(SwiperSlide)`
  &.swiper-slide {
    display: flex;
    align-items: center;

    inline-size: auto;

    &:not(:last-child) {
      margin-inline-end: 16px;

      ${props => props.theme.breakpoints.up("desktop")} {
        margin-inline-end: 14px;
      }
    }
  }
`;
