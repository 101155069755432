import type { FunctionComponent } from "react";
import { Suspense, useEffect, useRef } from "react";
import { graphql, useQueryLoader } from "react-relay";

import type { AppBarVariant } from "scmp-app/components/app-bar/types";
import { Edition, useEdition } from "scmp-app/lib/edition";
import type { headerOnelineMenuContainerQuery } from "scmp-app/queries/__generated__/headerOnelineMenuContainerQuery.graphql";

import { HeaderOnelineMenu } from "./header-oneline-menu";
import { HeaderOnelineMenuStyle } from "./header-oneline-menu/header-oneline-menu-style";
import { AllMenuContainer, Container, Divider, StyledHeaderAllMenu } from "./styles";

export const query = graphql`
  query headerOnelineMenuContainerQuery($topicQueueName: String) {
    ...headerOnelineMenuAppConfigQuery
    ...headerOnelineMenuStyleQuery
    ...headerAllMenuQuery
  }
`;

export type Props = {
  appBarVariant?: AppBarVariant;
  className?: string;
};

export const HeaderOnelineMenuContainer: FunctionComponent<Props> = ({
  appBarVariant,
  className,
}) => {
  const containerReference = useRef<HTMLDivElement>(null);
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<headerOnelineMenuContainerQuery>(query);
  const { get: getEdition } = useEdition({ initializeWithValue: true });
  useEffect(() => {
    const topicQueueMap = {
      [Edition.Asia]: "related_topic_homepage_asia_",
      [Edition.HongKong]: "related_topic_homepage_hk_",
      [Edition.International]: "related_topic_homepage_int_",
    };
    loadQuery({ topicQueueName: topicQueueMap[getEdition()] });
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery, getEdition]);

  const handleRenderOnelineMenu = () => {
    if (!queryReference) return null;

    switch (appBarVariant) {
      case "scmp/magazines-style":
        return <HeaderOnelineMenuStyle queryReference={queryReference} />;
      default:
        return <HeaderOnelineMenu queryReference={queryReference} />;
    }
  };

  const handleRenderAllMenu = () => {
    if (!queryReference) return null;
    return (
      <AllMenuContainer>
        {appBarVariant !== "scmp/magazines-style" && <Divider>-</Divider>}
        <StyledHeaderAllMenu
          containerReference={containerReference}
          queryReference={queryReference}
        />
      </AllMenuContainer>
    );
  };

  return (
    <Container $variant={appBarVariant} className={className} ref={containerReference}>
      <Suspense>
        {queryReference && (
          <>
            {handleRenderOnelineMenu()}
            {handleRenderAllMenu()}
          </>
        )}
      </Suspense>
    </Container>
  );
};

HeaderOnelineMenuContainer.displayName = "HeaderOnelineMenuContainer";
