import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

export const HeaderAllMenuTitle = styled.div`
  margin-block-end: 16px;

  color: #001246;
  font-weight: 700;
  font-size: 16px;
  font-family: ${fontRobotoCondensed};
  line-height: 19px;
`;
