export const application = {
  artifacts: {
    applicationId: "artifacts",
    entityId: "artifacts",
    name: "Artifacts",
    urlAlias: "https://artifact.scmp.com",
  },
  chinaPowerPlayers: {
    applicationId: "china-power-players",
    entityId: "china-power-players",
    name: "China Power Players",
    urlAlias: "https://sc.mp/china-power-players",
  },
  oneHundredTopTables: {
    applicationId: "one-hundred-top-tables",
    entityId: "one-hundred-top-tables",
    name: "100 Top Tables",
    urlAlias: "https://100toptables.scmp.com",
  },
  posties: {
    applicationId: "posties",
    entityId: "posties",
    name: "Posties",
    urlAlias: "/posties",
  },
  scmpLive: {
    applicationId: "scmp-live",
    entityId: "scmp-live",
    name: "SCMP Live",
    urlAlias: "http://live.scmp.com",
  },
  scmpPlus: {
    applicationId: "scmp-plus",
    entityId: "scmp-plus",
    name: "SCMP Plus",
    urlAlias: "/plus",
  },
  theStore: {
    applicationId: "the-store",
    entityId: "the-store",
    name: "SCMP Store",
    urlAlias: "https://store.scmp.com",
  },
  yp: {
    applicationId: "yp",
    entityId: "yp",
    name: "YP",
    urlAlias: "https://www.scmp.com/yp",
  },
};
