/**
 * @generated SignedSource<<4f8e5be10aa1c938e0d9ac3719c43afa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type headerAllMenuQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"headerAllMenuColumnistsQuery" | "headerAllMenuFocusQuery" | "headerAllMenuHighlightsQuery" | "headerAllMenuSectionAppConfigQuery" | "headerAllMenuTopicsQuery">;
  readonly " $fragmentType": "headerAllMenuQuery";
};
export type headerAllMenuQuery$key = {
  readonly " $data"?: headerAllMenuQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"headerAllMenuQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "headerAllMenuQuery",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "headerAllMenuColumnistsQuery"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "headerAllMenuFocusQuery"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "headerAllMenuHighlightsQuery"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "headerAllMenuSectionAppConfigQuery"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "headerAllMenuTopicsQuery"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "6b8b395eb060f9969923e6fbc42370cb";

export default node;
