import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { BaseEntityLink } from "scmp-app/components/base-entity-link";
import { EntityLink } from "scmp-app/components/entity-link";

export const HeaderAllMenuItemLinkCss = css`
  color: #333333;
  font-family: ${fontRobotoCondensed};
  line-height: normal;

  &:hover {
    color: #2c4692;
    text-decoration: underline;
  }
`;

export const HeaderAllMenuItemLink = styled(EntityLink)`
  ${HeaderAllMenuItemLinkCss}
`;

export const HeaderAllMenuItemBaseLink = styled(BaseEntityLink)`
  ${HeaderAllMenuItemLinkCss}
`;
