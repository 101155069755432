import styled from "@emotion/styled";

import { BaseEntityLink } from "scmp-app/components/base-entity-link";

export const StyledBaseEntityLink = styled(BaseEntityLink)`
  svg {
    inline-size: auto;
    block-size: 34px;
    ${props => props.theme.breakpoints.up("mediumDesktop")} {
      block-size: auto;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: start;

  inline-size: 163px;
  ${props => props.theme.breakpoints.up("mediumDesktop")} {
    inline-size: 193px;
  }

  ${StyledBaseEntityLink}:not(:last-child) {
    margin-block-end: 10px;
  }
`;
