import type { FunctionComponent } from "react";

import {
  HeaderAllMenuItemLinkContainer,
  HeaderAllMenuTitle,
} from "scmp-app/components/header/header-oneline-menu-container/header-all-menu/common";

import { Container, StyledLink } from "./styles";

export type Props = {
  className?: string;
};

export const HeaderAllMenuEpaper: FunctionComponent<Props> = ({ className }) => (
  <Container className={className}>
    <HeaderAllMenuTitle>ePaper</HeaderAllMenuTitle>
    <HeaderAllMenuItemLinkContainer>
      {/* update this when we can move ePaper items to drupal config
            in that case HeaderAllMenuItemLink should be used */}
      <StyledLink pathname="/epaper" target="_blank">
        ePaper
      </StyledLink>
    </HeaderAllMenuItemLinkContainer>
  </Container>
);

HeaderAllMenuEpaper.displayName = "HeaderAllMenuEpaper";
